<template>
  <div>
    <Spinner v-if="isLoading" />
    <vca-field
      v-else-if="value.internal_asp"
      :label="$t('events.internal_asp.header')"
    >
      <div>{{ $t("events.internal_asp.description") }}</div>
      <div>
        <h3>{{ $t("events.internal_asp.select.label") }}</h3>
        <vca-dropdown
          ref="internal_asp"
          v-model="internal_asp"
          :options="internalAspList"
          :rules="$v.value.internal_asp"
          label=""
          :errorMsg="$t('events.internal_asp.select.errorMsg')"
          :placeholder="$t('events.internal_asp.select.placeholder')"
        />
      </div>
      <div v-if="value.internal_asp.id">
        <div>
          <h3>{{ $t("events.internal_asp.name.label") }}</h3>
          <vca-input
            ref="full_name"
            disabled
            v-model="full_name"
            :errorMsg="$t('events.internal_asp.name.errorMsg')"
            :placeholder="$t('events.internal_asp.name.placeholder')"
          />
        </div>
        <div>
          <h3>{{ $t("events.internal_asp.email.label") }}</h3>
          <vca-input
            ref="email"
            disabled
            v-model="email"
            :errorMsg="$t('events.internal_asp.email.errorMsg')"
            :placeholder="$t('events.internal_asp.email.placeholder')"
          />
        </div>
        <div>
          <h3>{{ $t("events.internal_asp.phone.label") }}</h3>
          <vca-input
            ref="phone"
            v-model="phone"
            :errorMsg="$t('events.internal_asp.phone.errorMsg')"
            :placeholder="$t('events.internal_asp.phone.placeholder')"
          />
        </div>
      </div>
    </vca-field>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Spinner from "@/components/utils/Spinner.vue";
export default {
  name: "EventsFormAsp",
  components: { Spinner },
  data() {
    return {
      isLoading: false,
      internalAspList: [],
    };
  },
  props: {
    value: {
      type: Object,
      default: null,
    },
  },
  validations() {
    return this.validation;
  },
  mounted() {
    this.updateInternalAspList();
  },
  methods: {
    validate() {
      this.$refs.internal_asp.validate();
    },
    updateInternalAspList() {
      let data = { system_roles: ["employee"] };
      this.isLoading = true;
      this.$store
        .dispatch({ type: "users/queryList", data: data })
        .then((response) => {
          this.internalAspList = response.filter((el) => {
            el.title = el.full_name;
            el.label = el.full_name;
            el.subtitle = el.display_name + " <" + el.email + ">";
            el.value = el.id;
            if (this.value.internal_asp_id == "" && el.id == this.user.id) {
              this.internal_asp = [el];
            }
            return true;
          });
          this.isLoading = false;
        });
    },
  },
  computed: {
    full_name() {
      return this.value.internal_asp.full_name;
    },
    email() {
      return this.value.internal_asp.email;
    },
    phone: {
      get() {
        return this.value.internal_asp.profile.phone;
      },
      set(value) {
        this.value.phone = value;
      },
    },
    internal_asp: {
      get() {
        return [{ value: this.value.internal_asp.id }];
      },
      set(value) {
        let newValue = this.value;
        newValue.internal_asp_id = value.length ? value[0].id : "";
        newValue.internal_asp = value.length ? value[0] : "";
        this.value = newValue;
      },
    },
    userSelection() {
      return this.usersList.filter((el) => {
        el.title = el.full_name;
        el.label = el.full_name;
        el.subtitle = el.display_name + " <" + el.email + ">";
        el.value = el.id;
        return true;
      });
    },
    ...mapGetters({
      user: "user/current",
      usersList: "users/list",
      validation: "events/validations",
    }),
  },
};
</script>

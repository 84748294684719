<template>
  <vca-field
    v-if="value.external_asp"
    :label="$t('events.external_asp.header')"
  >
    <div>{{ $t("events.external_asp.description") }}</div>
    <div>
      <h3>{{ $t("events.external_asp.name.label") }}</h3>
      <vca-input
        ref="full_name"
        v-model="value.external_asp.full_name"
        :errorMsg="$t('events.external_asp.name.errorMsg')"
        :placeholder="$t('events.external_asp.name.placeholder')"
      />
    </div>
    <div>
      <h3>{{ $t("events.external_asp.email.label") }}</h3>
      <vca-input
        ref="email"
        v-model="value.external_asp.email"
        :errorMsg="$t('events.external_asp.email.errorMsg')"
        :placeholder="$t('events.external_asp.email.placeholder')"
      />
    </div>
    <div>
      <h3>{{ $t("events.external_asp.phone.label") }}</h3>
      <vca-input
        v-model="value.external_asp.phone"
        :placeholder="$t('events.external_asp.phone.placeholder')"
      />
    </div>
  </vca-field>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "EventsFormAsp",
  props: {
    value: {
      type: Object,
      default: null,
    },
  },
  validations() {
    return this.validation;
  },
  computed: {
    ...mapGetters({
      validation: "events/validations",
    }),
  },
};
</script>

<template>
  <div>
    <vca-field :label="$t('events.crew.header')">
      <vca-row>
        <div class="short">{{ $t("events.crew.select.label") }}</div>
        <div class="long">
          <vca-dropdown
            ref="crew"
            v-model="crewsList"
            :options="crewList"
            :title="$t('events.crew.select.title')"
            :rules="$v.value.crew"
            label=""
            :errorMsg="$t('events.crew.select.errorMsg')"
            :placeholder="$t('events.crew.select.placeholder')"
          />
        </div>
      </vca-row>
    </vca-field>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "EventsFormCrewSelect",
  props: {
    value: {
      type: Object,
      default: null,
    },
  },
  validations() {
    return this.validation;
  },
  methods: {
    validate() {
      this.$refs.crew.validate();
    },
    updateCrewAspList() {
      let data = { crew_id: this.value.crew.id };
      this.$store
        .dispatch({ type: "users/queryList", data: data })
        .then((response) => {
          this.value.event_asp_list = response.filter((el) => {
            el.title = el.full_name;
            el.label = el.full_name;
            el.subtitle = el.display_name + " <" + el.email + ">";
            el.value = el.id;
            return true;
          });
        });
    },
  },
  computed: {
    crewsList: {
      set(value) {
        if (value.length > 0) {
          this.value.crew_id = value[0].value;
          this.value.crew.id = value[0].value;
          this.value.crew.name = value[0].name;
          this.value.crew.email = value[0].email;
          //this.updateCrewAspList()
        } else {
          this.value.crew_id = "";
          this.value.crew.id = "";
          this.value.crew.name = "";
          this.value.crew.email = "";
        }
        this.value.event_asp_id = "";
        this.value.event_asp.id = "";
        this.value.event_asp.email = "";
        this.value.event_asp.profile
          ? (this.value.event_asp.profile.phone = "")
          : null;
        this.value.event_asp.display_name = "";
        this.value.event_asp.first_name = "";
        this.value.event_asp.last_name = "";
      },
      get() {
        return !this.value.crew ? [] : [{ value: this.value.crew.id }];
      },
    },
    ...mapGetters({
      user: "user/current",
      crewList: "crews/dropdown",
      validation: "events/validations",
    }),
  },
};
</script>

<template>
  <vca-field v-if="value.application" :label="$t('events.dates.header')">
    <vca-column>
      <h3>{{ $t("events.dates.event_start_datetime") }}</h3>
      <vca-row>
        <vca-input-date
          ref="start_date"
          v-model="start_date"
          @input="setEndDate"
          :default="Date.now() / 1000"
          :language="this.$i18n.locale"
          first
          :rules="$v.value.start_at"
          :errorMsg="$t('events.start_at.errorMsg')"
          :placeholder="$t('events.start_at.placeholder')"
        />
        <vca-daytime
          ref="start_time"
          v-model="start_time"
          last
          :placeholder="$t('events.dates.event_starttime.placeholder')"
        />
      </vca-row>
    </vca-column>
    <vca-column>
      <h3>{{ $t("events.dates.event_end_datetime") }}</h3>
      <vca-row>
        <vca-input-date
          ref="end_date"
          v-model="end_date"
          :default="Date.now() / 1000"
          first
          :rules="$v.value.end_at"
          :language="this.$i18n.locale"
          :errorMsg="$t('events.dates.event_enddate.errorMsg')"
          :placeholder="$t('events.dates.event_enddate.placeholder')"
        />
        <vca-daytime
          ref="end_time"
          v-model="end_time"
          last
          :placeholder="$t('events.dates.event_endtime.placeholder')"
        />
      </vca-row>
    </vca-column>
  </vca-field>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "EventsFormTimes",
  props: {
    value: {
      type: Object,
      default: null,
    },
  },
  validations() {
    return this.validation;
  },
  methods: {
    validate() {
      if (this.$refs.start_date) {
        this.$refs.start_date.validate();
        this.$refs.start_time.validate();
        this.$refs.end_date.validate();
        this.$refs.end_time.validate();
      }
    },
    setEndDate() {
      this.value.end_at = this.value.start_at + 22000;
      this.validate();
    },
  },
  watch: {
    end_time() {
      this.validate();
    },
  },
  computed: {
    start_time: {
      get() {
        let date = new Date(this.value.start_at * 1000);
        return {
          HH: ("0" + date.getHours()).slice(-2),
          mm: ("0" + ((Math.round(date.getMinutes() / 15) * 15) % 60)).slice(
            -2
          ),
        };
      },
      set(value) {
        let date = new Date(this.value.start_at * 1000);
        date.setHours(value.HH);
        date.setMinutes(value.mm);

        let newValue = this.value;
        newValue.start_at = date.getTime() / 1000;
        this.value = newValue;
      },
    },
    start_date: {
      get() {
        return this.value.start_at;
      },
      set(value) {
        let date = new Date(this.value.start_at * 1000);
        let newDate = new Date(value * 1000);
        date.setFullYear(
          newDate.getFullYear(),
          newDate.getMonth(),
          newDate.getDate()
        );

        let newValue = this.value;
        newValue.start_at = date.getTime() / 1000;
        this.value = newValue;
      },
    },
    end_time: {
      get() {
        let date = new Date(this.value.end_at * 1000);
        return {
          HH: ("0" + date.getHours()).slice(-2),
          mm: ("0" + ((Math.round(date.getMinutes() / 15) * 15) % 60)).slice(
            -2
          ),
        };
      },
      set(value) {
        let date = new Date(this.value.end_at * 1000);
        date.setHours(value.HH);
        date.setMinutes(value.mm);

        let newValue = this.value;
        newValue.end_at = date.getTime() / 1000;
        this.value = newValue;
      },
    },
    end_date: {
      get() {
        return this.value.end_at;
      },
      set(value) {
        let date = new Date(this.value.end_at * 1000);
        let newDate = new Date(value * 1000);
        date.setFullYear(
          newDate.getFullYear(),
          newDate.getMonth(),
          newDate.getDate()
        );

        let newValue = this.value;
        newValue.end_at = date.getTime() / 1000;
        this.value = newValue;
      },
    },
    ...mapGetters({
      validation: "events/validations",
    }),
  },
};
</script>

<template>
  <vca-column>
    <vca-dropdown
      :taggable="true"
      :multiple="true"
      v-model="artists"
      :options="artistList"
      label=""
      :placeholder="$t('events.artist.placeholder')"
    ></vca-dropdown>
  </vca-column>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "ArtistDropdown",
  props: {
    value: {
      type: Object,
      default: null,
    },
  },
  computed: {
    ...mapGetters({
      artistList: "artists/artistList",
    }),
    artists: {
      get() {
        return !this.value.artists
          ? []
          : this.value.artists.map((el) => ({
              title: el.name,
              value: el.id,
              label: el.name,
            }));
      },
      set(value) {
        let newValue = this.value;

        // if artist doesnt exists, create id
        if (value.length > 0 && !value[value.length - 1].value) {
          const artist = value[value.length - 1];
          // Check if artist already exists
          const currentArtist = this.artistList.find(
            (el) => el.label.toLowerCase() == artist.label.toLowerCase()
          );
          if (currentArtist) {
            // If artist exists, check if it is already selected
            const selectedArtist = this.value.artists.find(
              (el) => el.name.toLowerCase() == artist.label.toLowerCase()
            );

            // If it is selected, remove the currently added tag
            if (selectedArtist) {
              this.artists = this.artists.slice(0, this.artists.length);
              return false;
            }

            // If it is not selected, add it to the artists
            newValue.artists.push({
              name: currentArtist.label,
              id: currentArtist.value,
            });
            newValue.artist_ids = newValue.artists.map((el) => el.id);
            this.value = newValue;
          } else if (
            confirm(
              this.$t("events.artist.confirm", {
                0: artist.label,
              })
            )
          ) {
            // If it doesnt exist, create it
            this.$store.commit("artists/create", {
              name: artist.label,
            });
            this.$store
              .dispatch({ type: "artists/createReq" })
              .then((response) => {
                newValue.artists.push({
                  name: response.name,
                  id: response.id,
                });
                newValue.artist_ids = newValue.artists.map((el) => el.id);
                this.value = newValue;
                this.$store.dispatch("artists/list");
              });
          } else {
            value.pop();
            this.notification({
              title: this.$t("messages.title.hint"),
              body: this.$t("messages.artist.not_added"),
              type: "warn",
            });
          }
        } else {
          newValue.artists = value.map((el) => ({
            name: el.label,
            id: el.value,
          }));
          newValue.artist_ids = newValue.artists.map((el) => el.id);
          this.value = newValue;
        }
      },
    },
  },
};
</script>

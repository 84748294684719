<template>
  <div>
    <Spinner v-if="isLoading" />
    <vca-field v-else :label="$t('events.event_asp.header')">
      <div>{{ $t("events.event_asp.description") }}</div>
      <div>
        <h3>{{ $t("events.event_asp.select.label") }}</h3>
        <vca-dropdown
          ref="event_asp"
          v-model="event_asp"
          :options="crewAspList"
          label=""
          :rules="$v.value.event_asp"
          :errorMsg="$t('events.event_asp.select.errorMsg')"
          :placeholder="$t('events.event_asp.select.placeholder')"
        />
      </div>
      <div v-if="hasAsp">
        <div>
          <h3>{{ $t("events.event_asp.name.label") }}</h3>
          <vca-input
            disabled
            v-model="full_name"
            :placeholder="$t('events.event_asp.name.placeholder')"
          />
        </div>
        <div v-if="hasSystemPermission()">
          <h3>{{ $t("events.event_asp.email.label") }}</h3>
          <vca-input
            disabled
            v-model="email"
            :placeholder="$t('events.event_asp.email.placeholder')"
          />
        </div>
        <div v-if="hasSystemPermission()">
          <h3>{{ $t("events.event_asp.phone.label") }}</h3>
          <vca-input
            :disabled="initial_event_asp"
            v-model="phone"
            :placeholder="$t('events.event_asp.phone.placeholder')"
          />
        </div>
      </div>
    </vca-field>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Spinner from "@/components/utils/Spinner.vue";
export default {
  name: "EventsFormAsp",
  components: { Spinner },
  data() {
    return {
      isLoading: false,
      crewAspList: [],
      initial_event_asp: false,
    };
  },
  props: {
    value: {
      type: Object,
      default: null,
    },
  },
  mounted() {
    if (this.value.crew) {
      this.updateCrewAspList();
    }
  },
  validations() {
    return this.validation;
  },
  watch: {
    "value.crew.name": {
      handler() {
        this.updateCrewAspList();
      },
      deep: true,
    },
    "value.event_asp": {
      handler(value) {
        this.initial_event_asp = value.profile && value.profile.phone != "";
      },
      deep: true,
    },
  },
  methods: {
    validate() {
      this.$refs.event_asp.validate();
    },
    updateCrewAspList() {
      this.isLoading = true;
      if (!this.hasSystemPermission()) {
        this.$store.dispatch({ type: "users/publicCrew" }).then((response) => {
          this.crewAspList =
            response == null || response.length == 0
              ? []
              : response.filter((el) => {
                  el.title = el.full_name;
                  el.label = el.full_name;
                  el.subtitle = el.display_name;
                  el.value = el.id;
                  return true;
                });
          this.isLoading = false;
        });
      } else {
        let data = { crew_id: this.value.crew_id, limit: 99999 };
        this.$store
          .dispatch({ type: "users/queryList", data: data })
          .then((response) => {
            this.crewAspList =
              response == null || response.length == 0
                ? []
                : response.filter((el) => {
                    el.title = el.full_name;
                    el.label = el.full_name;
                    el.subtitle = el.display_name;
                    el.value = el.id;
                    return true;
                  });
            this.isLoading = false;
          });
      }
    },
  },
  computed: {
    hasAsp() {
      return this.value && this.value.event_asp_id != "";
    },
    full_name() {
      return this.value.event_asp && this.value.event_asp.first_name
        ? this.value.event_asp.first_name + " " + this.value.event_asp.last_name
        : "";
    },
    email() {
      return this.value.event_asp ? this.value.event_asp.email : "";
    },
    phone: {
      get() {
        return this.value.event_asp && this.value.event_asp.profile
          ? this.value.event_asp.profile.phone
          : "";
      },
      set(value) {
        return (this.value.event_asp.profile.phone = value);
      },
    },
    event_asp: {
      get() {
        return this.hasAsp ? [{ value: this.value.event_asp_id }] : [];
      },
      set(value) {
        let newValue = this.value;
        newValue.event_asp_id = value.length ? value[0].id : "";
        newValue.event_asp = value.length ? value[0] : "";
        this.value = newValue;
      },
    },
    userSelection() {
      return this.usersList.filter((el) => {
        el.title = el.first_name + " " + el.last_name;
        el.label = el.first_name + " " + el.last_name;
        el.subtitle = el.display_name + " <" + el.email + ">";
        el.value = el.id;
        return true;
      });
    },
    ...mapGetters({
      hasSystemPermission: "user/roles/hasSystemPermission",
      validation: "events/validations",
      usersList: "users/list",
    }),
  },
};
</script>

<template>
  <vca-column>
    <vca-row>
      <div>
        <span class="bold">{{ $t("events.creator.label") }}: </span>
        <span>
          {{ value.creator.first_name }} {{ value.creator.last_name }}
        </span>
      </div>
      <div class="vca-right">
        {{ $t("events.creator.modified") }}:
        {{ datetime(value.modified.updated) }} {{ $t("locale.time") }}
      </div>
    </vca-row>
    <div>
      <span class="bold">{{ $t("events.creator.created") }}: </span>
      {{ datetime(value.modified.created) }} {{ $t("locale.time") }}
    </div>
    <div>
      <span class="bold">{{ $t("table.header.state") }}: </span>
      {{ $t("events.list.event_states." + value.event_state.state) }}
      <span v-if="value.event_state.state == 'requested'">
        {{ requestedTo }}
      </span>
    </div>
  </vca-column>
</template>

<script>
export default {
  name: "EventsFormMeta",
  props: {
    value: {
      type: Object,
      default: null,
    },
  },
  methods: {
    navUser(user_id) {
      this.$router.push({ path: "/users/" + user_id });
    },
  },
  computed: {
    requestedTo() {
      return this.value.event_state.internal_confirmation != ""
        ? this.$t("events.list.event_states.to_crew")
        : this.$t("events.list.event_states.to_office");
    },
  },
};
</script>

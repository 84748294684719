<template>
  <div>
    <vca-card v-if="crewsList.length > 0">
      <EventAsp v-model="value" ref="event_asp" />
    </vca-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import EventAsp from "@/components/events/form/EventAsp";
export default {
  name: "EventsFormCrews",
  components: { EventAsp },
  props: {
    value: {
      type: Object,
      default: null,
    },
  },
  validations() {
    return this.validation;
  },
  methods: {
    validate() {
      if (this.crewsList.length > 0) {
        this.$refs.event_asp.validate();
      }
    },
  },
  computed: {
    crewsList: {
      get() {
        return this.value.crew && this.value.crew_id ? [this.value.crew] : [];
      },
    },
    ...mapGetters({
      validation: "events/validations",
    }),
  },
};
</script>

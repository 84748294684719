<template>
  <vca-card>
    <h2>{{ $t("events.add.header") }}</h2>
    <EventAdd />
  </vca-card>
</template>

<script>
import EventAdd from "@/components/events/EventAdd";
export default {
  name: "EventsAdd",
  components: { EventAdd },
  created() {
    this.$store.dispatch({ type: "crews/public" });
    this.$store.dispatch({ type: "users/list" });
    this.$store.dispatch({ type: "artists/list" });
    this.$store.dispatch({ type: "organizers/list" });
  },
};
</script>

<template>
  <vca-field v-if="value.event_tools" :label="$t('events.tools.header')">
    <vca-row>
      <div class="short">{{ $t("events.tools.list.label") }}</div>
      <div class="long">
        <vca-checkbox v-model="value.event_tools.tools" id="cups">{{
          $t("events.tools.list.cups")
        }}</vca-checkbox>
        <vca-checkbox v-model="value.event_tools.tools" id="guestlist">{{
          $t("events.tools.list.guestlist")
        }}</vca-checkbox>
        <vca-checkbox v-model="value.event_tools.tools" id="info">{{
          $t("events.tools.list.info")
        }}</vca-checkbox>
        <vca-checkbox v-model="value.event_tools.tools" id="donationbox">{{
          $t("events.tools.list.donationbox")
        }}</vca-checkbox>
        <vca-checkbox v-model="value.event_tools.tools" id="special">{{
          $t("events.tools.list.special")
        }}</vca-checkbox>
      </div>
    </vca-row>
    <vca-row
      v-if="
        value.event_tools.tools && value.event_tools.tools.includes('special')
      "
    >
      <div class="short">{{ $t("events.tools.special.label") }}</div>
      <div class="long">
        <vca-input
          v-model="value.event_tools.special"
          :placeholder="$t('events.tools.special.placeholder')"
        />
      </div>
    </vca-row>
  </vca-field>
</template>

<script>
export default {
  name: "EventsFormTools",
  props: {
    value: {
      type: Object,
      default: null,
    },
  },
};
</script>

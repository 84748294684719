<template>
  <vca-column>
    <vca-dropdown
      :taggable="true"
      v-model="organizer_id"
      :options="organizerList"
      label=""
      :placeholder="$t('events.organizer.placeholder')"
    ></vca-dropdown>
  </vca-column>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "OrganizerDropdown",
  props: {
    value: {
      type: Object,
      default: null,
    },
  },
  computed: {
    ...mapGetters({
      organizerList: "organizers/organizerList",
    }),
    organizer_id: {
      get() {
        return !this.value.organizer || this.value.organizer.id == ""
          ? []
          : [
              {
                title: this.value.organizer.name,
                label: this.value.organizer.name,
                value: this.value.organizer.id,
              },
            ];
      },
      set(value) {
        // if organizer doesnt exists, create id
        if (value.length > 0 && !value[0].value) {
          const currentOrganizer = this.organizerList.find(
            (el) => el.label.toLowerCase() == value[0].label.toLowerCase()
          );
          if (currentOrganizer) {
            this.value.organizer.name = currentOrganizer.label;
            this.value.organizer.id = currentOrganizer.value;
            return;
          }
          if (
            confirm(
              this.$t("events.organizer.confirm", {
                0: value[value.length - 1].label,
              })
            )
          ) {
            this.$store.commit("organizers/create", {
              name: value[value.length - 1].label,
            });
            this.$store
              .dispatch({ type: "organizers/createReq" })
              .then((response) => {
                this.value.organizer.name = response.name;
                this.value.organizer.id = response.id;
                this.$store.dispatch("organizers/list");
              });
          } else {
            value.pop();
            this.notification({
              title: this.$t("messages.title.hint"),
              body: this.$t("messages.organizer.not_added"),
              type: "warn",
            });
          }
        } else {
          this.value.organizer.name = value.length > 0 ? value[0].label : "";
          this.value.organizer.id = value.length > 0 ? value[0].value : "";
          this.value.organizer_id = value.length > 0 ? value[0].value : "";
        }
      },
    },
  },
};
</script>

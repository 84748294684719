<template>
  <div>
    <vca-card>
      <vca-accordion
        :header="res.crew.name"
        :key="index"
        v-for="(res, index) in crewsList"
      >
        <div slot="body">
          <vca-field :label="$t('events.general.header')">
            <div>
              <h3>
                {{ $t("events.general.location.label") }}
                <span v-if="res.location && res.location.name"
                  >({{ $t("events.general.location.current")
                  }}{{ res.location.name }}, {{ res.location.city }})</span
                >
              </h3>
              <div>
                <vca-row>
                  <vca-location
                    ref="location"
                    v-model="res.location"
                    :errorMsg="$t('events.location.errorMsg')"
                    type="establishment"
                  />
                  <div class="inline-infobox">
                    <vca-info-box>{{
                      $t("events.location.info")
                    }}</vca-info-box>
                  </div>
                </vca-row>
              </div>
            </div>
            <div>
              <h3>{{ $t("events.additional.label") }}</h3>
              <vca-textarea
                v-model="res.additional_information"
                :placeholder="$t('events.additional.placeholder')"
              />
            </div>
          </vca-field>

          <EventAsp v-model="crewsList[index]" ref="event_asp" />
          <EventApplicationSettings
            v-model="crewsList[index]"
            ref="application"
          />
          <Times v-model="crewsList[index]" ref="times" />
        </div>
      </vca-accordion>
    </vca-card>
  </div>
</template>

<script>
import EventAsp from "@/components/events/form/EventAsp";
import Times from "@/components/events/form/Times";
import EventApplicationSettings from "@/components/events/form/EventApplicationSettings";
import { mapGetters } from "vuex";
export default {
  name: "EventsFormCrews",
  components: { EventAsp, Times, EventApplicationSettings },
  validations() {
    return this.validation;
  },
  methods: {
    validate() {
      if (this.crewsList.length > 0) {
        //this.$refs.event_asp.validate()
        //this.$refs.times.validate()
        //this.$refs.application.validate()
      }
    },
  },
  computed: {
    crewsList: {
      get() {
        return this.tourCrewsList.events;
      },
    },
    ...mapGetters({
      validation: "events/validations",
      tourCrewsList: "events/tour/create",
    }),
  },
};
</script>

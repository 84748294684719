<template>
  <vca-field v-if="value.application" :label="$t('events.application.header')">
    <vca-column>
      <h3>{{ $t("events.application.application_time") }}</h3>
      <vca-row>
        <vca-input-date
          ref="application_start"
          v-model="application_start_date"
          :default="application_start_date"
          first
          :disabledValues="disabledStartValues"
          :rules="$v.value.application.start_date"
          :language="this.$i18n.locale"
          :errorMsg="$t('events.application.application_start.errorMsg')"
          :placeholder="$t('events.application.application_start.placeholder')"
        />
        <vca-input-date
          ref="application_end"
          v-model="application_end_date"
          :default="application_end_date"
          last
          :rules="$v.value.application.end_date"
          :language="this.$i18n.locale"
          :errorMsg="$t('events.application.application_end.errorMsg')"
          :placeholder="$t('events.application.application_end.placeholder')"
        />
      </vca-row>
    </vca-column>
    <vca-row>
      <h3>{{ $t("events.application.contingent.label") }}</h3>

      <div class="short">
        <vca-number
          v-model="supporter_count"
          :min="1"
          :max="null"
          :placeholder="$t('events.application.contingent.placeholder')"
        />
      </div>
    </vca-row>
  </vca-field>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "EventFormApplication",
  props: {
    value: {
      type: Object,
      default: null,
    },
  },
  validations() {
    return this.validation;
  },
  watch: {
    application: {
      handler() {
        this.validate();
      },
      deep: true,
    },
  },
  methods: {
    validate() {
      if (this.$refs.application_start) {
        this.$refs.application_start.validate();
        this.$refs.application_end.validate();
      }
    },
  },
  computed: {
    disabledStartValues() {
      return {
        from: new Date(this.value.application.end_date * 1000),
      };
    },
    application() {
      return this.value.application;
    },
    supporter_count: {
      set(value) {
        this.value.application.supporter_count = parseInt(value);
      },
      get() {
        return this.value.application.supporter_count;
      },
    },
    application_start_date: {
      set(value) {
        const date = new Date(value * 1000);
        // Set time to midnight (00:00)
        const midnightTimestamp = date; // new Date(date);
        midnightTimestamp.setHours(0, 0, 0, 0);
        const midnightTimestampInSeconds = midnightTimestamp.getTime();
        this.value.application.start_date = parseInt(
          midnightTimestampInSeconds / 1000
        );
      },
      get() {
        return this.value.application.start_date;
      },
    },
    application_end_date: {
      set(value) {
        const date = new Date(value * 1000);

        // Set time to 23:59
        const beforeMidnightTimestamp = new Date(date);
        beforeMidnightTimestamp.setHours(23, 59, 59, 0);
        const beforeMidnightTimestampInSeconds =
          beforeMidnightTimestamp.getTime();

        this.value.application.end_date = parseInt(
          beforeMidnightTimestampInSeconds / 1000
        );
      },
      get() {
        return this.value.application.end_date;
      },
    },
    ...mapGetters({
      validation: "events/validations",
      maxContingent: "events/maxContingent",
    }),
  },
};
</script>

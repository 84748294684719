<template>
  <div>
    <vca-field :label="$t('events.crew.header')">
      <vca-row>
        <div class="short">{{ $t("events.crew.select.label") }}</div>
        <div class="long">
          <vca-dropdown
            ref="crew"
            v-model="crewsList"
            :multiple="true"
            :options="crewList"
            :title="$t('events.crew.select.title')"
            label=""
            :errorMsg="$t('events.crew.select.errorMsg')"
            :placeholder="$t('events.crew.select.placeholder')"
          />
        </div>
      </vca-row>
    </vca-field>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "EventsFormCrewSelect",
  props: {
    value: {
      type: Object,
      default: null,
    },
  },
  validations() {
    return this.validation;
  },
  methods: {
    validate() {
      this.$refs.crew.validate();
    },
  },
  computed: {
    crewsList: {
      set(value) {
        if (value.length > 0) {
          this.$store.state.events.tour.create.events = [];

          value.forEach((el) => {
            var model = JSON.parse(JSON.stringify(this.eventModel));
            model.crew = { id: el.value, name: el.name, email: el.email };
            model.event_state.internal_confirmation = this.user.id;
            this.$store.state.events.tour.create.events.push(model);
          });
        } else {
          this.$store.state.events.tour.create.events = [];
        }
      },
      get() {
        return this.$store.state.events.tour.create.events.length == 0
          ? []
          : this.$store.state.events.tour.create.events.filter((el) => {
              el.title = el.crew.name;
              el.label = el.crew.name;
              el.value = el.crew.id;
              return true;
            });
      },
    },
    ...mapGetters({
      user: "user/current",
      crewList: "crews/dropdown",
      validation: "events/tour/validations",
      eventModel: "events/tour/model",
    }),
  },
};
</script>
